import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'

export function FacebookPixelScript() {
  const { events } = useRouter()
  const id = import.meta.graphCommerce.fbPixelId

  useEffect(() => {
    const onRouteChangeComplete = (url: string) => {
      if (typeof globalThis.fbq !== 'undefined') globalThis.fbq('track', 'PageView')
    }

    events.on('routeChangeComplete', onRouteChangeComplete)
    return () => events.off('routeChangeComplete', onRouteChangeComplete)
  }, [events])

  useEffect(() => {
    const interval = setInterval(() => {
      if (typeof globalThis.fbq !== 'undefined') {
        globalThis.fbq('track', 'PageView')
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <Script id={`fb-${id}`} strategy='afterInteractive'>{`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${id}');
    `}</Script>
      <noscript>
        <img
          height='1'
          width='1'
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`}
        />
      </noscript>
    </>
  )
}
