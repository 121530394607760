import { useStorefrontConfig } from '@graphcommerce/next-ui'
import { useEffect, useState } from 'react'
import { hotjar } from 'react-hotjar'

const useHotjar = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const hotjarId = useStorefrontConfig().hotjarId ?? import.meta.graphCommerce.hotjarId

  const hotjarVersion =
    useStorefrontConfig().hotjarVersion ?? import.meta.graphCommerce.hotjarVersion

  useEffect(() => {
    if (typeof hotjarId === 'string' && typeof hotjarVersion === 'string' && !isInitialized) {
      hotjar.initialize(parseFloat(hotjarId), parseFloat(hotjarVersion))
      setIsInitialized(true)
    }
  }, [hotjarId, hotjarVersion, isInitialized])
}

export { useHotjar }
