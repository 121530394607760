import { GraphQLProviderProps } from '@graphcommerce/graphql'
import type { PluginProps } from '@graphcommerce/next-config'

export const component = 'GraphQLProvider'
export const exported = '@graphcommerce/graphql'

function TempliCartGraphqlProvider(props: PluginProps<GraphQLProviderProps>) {
  const { Prev, policies = [], ...rest } = props
  const adjustedPolices = [...policies].map((policy) => {
    if (
      typeof policy?.Cart?.fields?.shipping_addresses === 'object' &&
      'merge' in policy.Cart.fields.shipping_addresses
    ) {
      delete policy.Cart.fields.shipping_addresses.merge
    }

    policy.AvailableShippingMethod = {
      keyFields: false,
    }

    policy.SelectedShippingMethod = {
      keyFields: false,
    }

    return policy
  })

  return <Prev {...rest} policies={[...adjustedPolices]} />
}

export const Plugin = TempliCartGraphqlProvider
