import { useApolloClient } from '@graphcommerce/graphql'
import Script from 'next/script'
import { CustomerDocument } from '../../graphql/Customer.gql'
import { useCallback } from 'react'

export function KlaviyoTrackingScript() {
  const publicKey = import.meta.graphCommerce.klaviyoPublicKey

  const client = useApolloClient()

  const handleScriptLoad = useCallback(() => {
    client.cache.watch({
      query: CustomerDocument, optimistic: false, callback: (result) => {
        if (result.result?.customer?.email) {
          const customer = result.result?.customer;
          var _learnq = globalThis._learnq || [];
          // Identifying a person and tracking special Klaviyo properties.
          _learnq.push(['identify', {
            '$email': customer?.email,
          }]);
        }
      }
    });
  }, [globalThis._learnq]);

  return (
    <Script src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${publicKey}`} async onLoad={handleScriptLoad} />
  )
}
